import { getCookie } from '@cleverrealestate/clever-helpers';

const VARIANT_JSON = {};

const cookieStringToJson = (cookieValue) => {
  if (cookieValue === null || cookieValue.replace(/\s/g, '') === '') {
    return;
  }

  // Split apart the variants by &
  const variants = cookieValue.split('&');

  variants.forEach((variant) => {
    // get the values by splitting ~
    if (variant.includes('~') && (variant.includes('true') || variant.includes('false'))) {
      const variantValues = variant.split('~');
      const title = variantValues[0];
      const active = variantValues[1];

      VARIANT_JSON[title] = {
        title,
        active: active === 'true',
      };
    }
  });
};

const isVariantActive = (variantName) => {
  const variantCookie = getCookie('X-Variant');

  if (!variantCookie) {
    return false;
  }

  if (VARIANT_JSON[variantName] !== undefined) {
    return VARIANT_JSON[variantName].active;
  }

  cookieStringToJson(variantCookie);

  if (VARIANT_JSON[variantName] !== undefined) {
    return VARIANT_JSON[variantName].active;
  }

  return false;
};

export default isVariantActive;
