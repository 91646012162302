/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import {
//   PrimaryCTA,
// } from '@cleverrealestate/clever-components';
import {
  styleConfig,
  CA_EventLocation,
} from '@cleverrealestate/clever-helpers';
////
// Primary CTA TEST
import PrimaryCTATest from '../components/PrimaryCTATest';
////
import withMUI from '../utils/MUI.utils';
import Layout from '../components/layout';
import SEO from '../components/Seo';
import HappyCoupleBg from '../images/img_comps/backgrounds/HappyCoupleBg';
import SavingsCounter from '../components/SavingsCounter';
import CleverLocationGray from '../images/img_comps/icons/CleverLocationGray';

const useStyles = makeStyles(theme => ({
  reviewPage: {
    position: 'relative',
    '@media screen and (min-width: 1920px)': {
      backgroundColor: '#3077dd',
    },
    [`@media screen and (max-width: ${styleConfig.media.mobile}px)`]: {
      ...theme.mixins.color.reviewsBackgroundGradient,
    },
  },
  bgPhoto: {
    width: '1920px',
    height: '100%',
    position: 'absolute',
    top: '0',
    right: '0',
    overflow: 'hidden',
    '@media screen and (max-width: 960px)': {
      right: 'auto',
      left: '-960px',
    },
  },
  heroElementsWrapper: {
    ...theme.mixins.layout.column.base,
    position: 'relative',
    paddingTop: '150px',
    width: '100%',
    alignItems: 'center',
    [`@media screen and (max-width: ${styleConfig.media.mobile}px)`]: {
      height: '100%',
    },
  },
  heroText: {
    ...theme.mixins.topBarAlign,
    ...theme.mixins.noSelect,
    position: 'relative',
    color: 'white',
    // minHeight: '153px',
    [`@media screen and (max-width: ${styleConfig.media.mobile}px)`]: {
      ...theme.mixins.layout.column.base,
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      marginLeft: '25px',
      marginRight: '25px',
      width: 'calc(100% - 50px)',
      '& h4': {
        fontSize: '1.2214285714285714rem',
      },
    },
    [`@media screen and (max-width: ${styleConfig.media.mobileSmall}px)`]: {
      '& h4': {
        fontSize: '1.1214285714285714rem',
      },
    },
  },
  heroSubText: {
    maxWidth: '490px',
    fontSize: '0.8571428571428571rem',
    fontWeight: 300,
    lineHeight: 1.5,
  },
  ctaWrapper: {
    justifyContent: 'left',
    maxWidth: '510px',
    [`@media screen and (max-width: ${styleConfig.media.mobile}px)`]: {
      ...theme.mixins.layout.column.base,
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '25px',
      color: 'white',
      textAlign: 'center',
      maxWidth: '100%',
    },
  },
  reviewsWrapper: {
    ...theme.mixins.layout.column.base,
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    height: '100%',
    [`@media screen and (min-width: ${styleConfig.media.mobile}px)`]: {
      minWidth: '1505px',
    },
  },
  trustPilotWrapper: {
    marginTop: '25px',
  },
  savingsBackground: {
    backgroundColor: '#3077dd',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  fullHeight: {
    height: '100%',
  },
}));

const ReviewsHeroText = () => {
  const classes = useStyles();
  useEffect(() => {
    const aScript = document.createElement('script');
    aScript.type = 'text/javascript';
    aScript.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
    aScript.async = true;
    document.head.appendChild(aScript);
    aScript.onload = () => {
      const trustbox = document.getElementById('trust-box-reviews-page');
      window.Trustpilot.loadFromElement(trustbox);
    };
  }, []);

  return (
    <div className={classes.heroText}>
      <Typography variant="h1" color="inherit" gutterBottom>
        Clever Real Estate Reviews - Don&#39;t just take our word for it.
      </Typography>
      <Typography className={classes.heroSubText} variant="h2" color="inherit" gutterBottom>
        Read reviews from hundreds of happy homeowners who used Clever,
        and then view Clever Partner Agents in your area.
      </Typography>
      <div className={classes.ctaWrapper}>
        <PrimaryCTATest
          pageLocation={CA_EventLocation.Hero}
          refKey="reviews"
          leftAlign
          locationIcon={<CleverLocationGray />}
        />
      </div>
      <div className={classes.trustPilotWrapper}>
        <div
          id="trust-box-reviews-page"
          className="trustpilot-widget"
          data-locale="en-US"
          data-template-id="539adbd6dec7e10e686debee"
          data-businessunit-id="5a95a55a4e08e80001875736"
          data-style-height="800px"
          data-style-width="100%"
          data-theme="dark"
          data-stars="4,5"
          data-schema-type="Organization"
        >
          <a
            href="https://www.trustpilot.com/review/listwithclever.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Trustpilot
          </a>
        </div>
      </div>
    </div>
  );
};

ReviewsHeroText.propTypes = {};

ReviewsHeroText.defaultProps = {};

const Reviews = () => {
  const classes = useStyles();
  const desktop = useMediaQuery(`(min-width: ${styleConfig.media.mobile + 1}px)`);

  return (
    <Layout
      colorTransitionStart={50}
      shadowTransitionStart={50}
      colorTransitionLength={100}
      shadowTransitionLength={100}
    >
      <SEO
        pathname="/reviews/"
        title="Clever Real Estate Reviews & Testimonials"
        description={'Find out what home buyers, sellers, and agents are'
          + ' saying about their experience with Clever Real Estate.'}
      />
      <div className={classes.reviewPage}>
        {desktop && (
          <>
            <div className={classes.bgPhoto}>
              <HappyCoupleBg className={classes.fullHeight} />
            </div>
          </>
        )}
        <div className={classes.heroElementsWrapper}>
          <ReviewsHeroText classes={classes} />
        </div>
        <div className={classes.savingsBackground}>
          <SavingsCounter />
        </div>
      </div>
    </Layout>
  );
};

Reviews.propTypes = {};

Reviews.defaultProps = {};

export default withMUI(Reviews);
