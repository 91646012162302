import React from 'react';
import PropTypes from 'prop-types';
import NewPrimaryCTA from '@cleverrealestate/clever-components-v2/dist/components/NewPrimaryCTA';
import {
  PrimaryCTA,
} from '@cleverrealestate/clever-components';
import { InterestTypes } from '@cleverrealestate/clever-helpers';
import isVariantActive from '../utils/variant-utils';

const PrimaryCTATest = ({
  ...other
}) => (
  <>
    {
      isVariantActive('MSFPrimaryTestV2') ? (
        <NewPrimaryCTA
          experimentString="Primary MSF Test v2 - Variant"
          {...other}
        />
      ) : (
        <PrimaryCTA
          experimentString="Primary MSF Test v2 - Control"
          {...other}
        />
      )
    }
  </>
);

PrimaryCTATest.propTypes = {
  selectDefault: PropTypes.string,
  instanceKey: PropTypes.string,
  darkText: PropTypes.bool,
  pageLocation: PropTypes.string,
  leftAlign: PropTypes.bool,
  locationIcon: PropTypes.any,
  willShowTerms: PropTypes.func,
};

PrimaryCTATest.defaultProps = {
  selectDefault: InterestTypes.selling,
  instanceKey: 'key',
  darkText: false,
  pageLocation: '',
  leftAlign: false,
  locationIcon: '',
  willShowTerms: () => {},
};

export default PrimaryCTATest;
