import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const HappyCoupleBg = () => (
  <StaticQuery
    query={graphql`
      query {
        happyCoupleBg: file(relativePath: { eq: "background/HappyCoupleBg.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, quality: 80)
          }
        }
      }
    `}
    render={data => <GatsbyImage image={data.happyCoupleBg.childImageSharp.gatsbyImageData} alt="Happy Couple" />}
  />
);
export default HappyCoupleBg;
