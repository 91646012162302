import React, { useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useCountUp } from 'react-countup';
import CommissionText1 from '../images/img_comps/text/CommissionText1';
import CommissionText2 from '../images/img_comps/text/CommissionText2';

const useStyles = makeStyles(theme => ({
  savingsWrapper: {
    color: 'white',
    width: '865px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '@media screen and (max-width: 960px)': {
      width: 'auto',
      flexDirection: 'column',
    },
  },
  counterWrapper: {
    ...theme.mixins.layout.row.base,
    alignItems: 'center',
  },
  leftText: {
    position: 'absolute',
    height: '20px',
    width: '350px',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  centerText: {
    width: '150px',
    textAlign: 'center',
  },
  rightText: {
    position: 'absolute',
    height: '20px',
    width: '350px',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));

////
// NOTE: Math time!
// a = $80,000,000 in real estate sold per mo by our agents
// b = 2% saved per sale
// c = (a * b) = $1,600,000 saved per mo
// d = c / 4 = $400,000 saved per week
// e = 604800 seconds in a week
// f = d / e = ~$0.66 saved per second
////
// 3/4/19 - f was too fast...changing to $0.30 saved per second
////
// g = $10,142,089 money clever has save customers as of march 3 2019
// h = 1551722828 epoch (seconds since jan 1 1970) as of march 3 2019
// random check in on price (between 10 - 25 seconds)
////

const SavingsCounter = () => {
  let timerId = null;
  const classes = useStyles();
  const getCurrentMoneySaved = () => {
    const date = new Date();
    const now = date.getTime() / 1000;
    const savedPerSecond = 0.3;
    const march4th = 1551722828;
    const secondsDif = now - march4th;
    const additionalSaved = Math.floor(secondsDif * savedPerSecond);
    const baseSaved = 10142089;
    return baseSaved + additionalSaved;
  };

  const { countUp, update } = useCountUp({
    start: getCurrentMoneySaved() - 245,
    end: getCurrentMoneySaved(),
    duration: 8,
    separator: ',',
  }, []);

  const updateCounter = () => {
    update(getCurrentMoneySaved());
    const randomTimeout = Math.floor(Math.random() * (20000 - 10000)) + 10000; // random timeout in 10 - 20 seconds
    timerId = setTimeout(updateCounter, randomTimeout);
  };

  useEffect(() => {
    updateCounter();

    return function cleanup() {
      clearTimeout(timerId);
    };
  }, []);
  const desktop = useMediaQuery('(max-width: 960px)');
  return (
    <div className={classes.savingsWrapper}>
      {desktop && (
        <>
          <Typography variant="h4" color="inherit">
            ${countUp}
          </Typography>
          <Typography variant="h5" color="inherit">
            Saved in Commissions
          </Typography>
        </>
      )}
      {!desktop && (
        <>
          {/* <Typography className={classes.leftText} variant="h5" color="inherit">
                Clever has saved homeowners
                </Typography> */}
          <div className={classes.leftText}>
            <CommissionText1 />
          </div>
          <Typography className={classes.centerText} variant="h4" color="inherit">
            ${countUp}
          </Typography>
          <div className={classes.rightText}>
            <CommissionText2 />
          </div>
          {/* <Typography className={classes.rightText} variant="h5" color="inherit">
                  in commissions and counting.
                </Typography> */}
        </>
      )}
    </div>
  );
};

SavingsCounter.propTypes = {};

SavingsCounter.defaultProps = {};

export default SavingsCounter;
